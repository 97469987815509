import React, { useState, useEffect } from "react";
import "./style.css";
import Swal from "sweetalert2";
import { useParams, useSearchParams, useNavigate, useLocation } from "react-router-dom";
import {
  base_url,
  deleteUserEvidence,
  disconnectStrava,
  getStravaActivity,
  getUserEvidences,
  submitEvidence,
} from "../../globalService/api.service";
import starvaLogo from "../../assets/starva-logo.png";
import disconnectLogo from "../../assets/disconnect.png"


const Modal = ({ isOpen, onClose, getUser, status, app }) => {
  const [disEvedenceKm, setDisEvedenceKm] = useState(null);
  const [durEvidenceSec, setDurEvidenceSec] = useState(null);
  const [activeOption, setActiveOption] = useState(1);
  const [appActivityData, setAppActivityData] = useState([]);
  const kmToMilesConversion = 0.621371;
  const distanceInMiles = disEvedenceKm * kmToMilesConversion;

  const navigate = useNavigate();
  const location = useLocation()
  useEffect(() => {
    getStrava()
  
    
  }, [])
  
  useEffect(() => {
    if (status) setActiveOption(2);

  }, [status]);

  const getStrava = async () => {
    try {
      const activityData = await getStravaActivity(userId)
    console.log("activityData", activityData);
    setAppActivityData(activityData.data)

    } catch (error) {
      console.log("error", error);
      
    }
    
    
  }



  // useEffect(() => {
  //     getStrava()
  // }, []);


  const handleOptionSelect = (key) => {
    setActiveOption(key);
  };

  const handleDistanceChange = (disEve) => {
    console.log("disEve", disEve);

    setDisEvedenceKm(disEve);
  };

  const handleDurationChange = (durEve) => {
    console.log("durEve", durEve);
    setDurEvidenceSec(durEve);
  };

  const params = useParams();
  const { brandId, productId, userId } = params;

  if (!isOpen) return null;

  const onSubmitEvidence = async () => {
    console.log("on submit");

    Swal.fire({
      title: `Do you want to submit ${disEvedenceKm}KM / ${distanceInMiles.toFixed(2)} Miles?`,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Submit",
      denyButtonText: `Don't save`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          let response = await submitEvidence(productId, userId, {
            distanceKm: disEvedenceKm,
            durationSec: durEvidenceSec,
          });
          if (response?.is_success) {
            getUser();

            Swal.fire(
              "Evidence Saved",
              `Distance: ${disEvedenceKm} Km ${durEvidenceSec ? `| Duration: ${durEvidenceSec} Second` : ""
              }`,
              "success"
            );
          } else {
            Swal.fire("Something went wrong", "Try again later", "error");
          }
          setDisEvedenceKm(null);
          setDurEvidenceSec(null);
          clearSelection()
          onClose();
        } catch (error) {
          console.log("err", error);
          Swal.fire("Something went wrong", "Try again later", "error");
          setDisEvedenceKm(null);
          setDurEvidenceSec(null);
          clearSelection()
          onClose();
        }
      }
    });
  };

  const tabs = [
    { id: 1, name: "Add Activity", hideIfCompleted: true },
    { id: 2, name: "History" },
  ];

  const configureAndGetTabs = () => {
    if (status) {
      return tabs.filter((tab) => !tab.hideIfCompleted);
    } else return tabs;
  };

  const evidenceFormConfig = [
    {
      key: "distance",
      label: "Distance",
      component: (
        <DistanceConverter handleDistanceChange={(dis) => handleDistanceChange(dis)} dis={disEvedenceKm} />
      ),
    },
    {
      key: "duration",
      label: "Duration (HH:MM:SS)",
      component: (
        <DurationConverter handleDurationChange={(dur) => handleDurationChange(dur)} dur={durEvidenceSec} />
      ),
    },
    {
      key: "image",
      label: "Upload your evidence",
      component: (
        <ImageUploader />
      ),
    },
  ];



  const appsToConnect = [
    {
      name: "strava",
      logo: starvaLogo,
      redirectUrl: `${base_url}/auth/strava/connect/${brandId}/${productId}/${userId}`,
    }
  ];

  const handleClick = (redirectUrl) => {
    if (!redirectUrl) return;
    window.location.href = redirectUrl;
  };

  const handleActivitySelect = (activity) => {
    const appActivityDataCopy = JSON.parse(JSON.stringify(appActivityData))
    const data = appActivityDataCopy.find(e => e.id === activity.id)
    data.isSelected = !data.isSelected

    const total = {distance: 0, duration: 0}
    appActivityDataCopy.forEach(element => {
      if(element.isSelected) {
        total.distance = total.distance + element.distance
        total.duration = total.duration + element.duration
      }
    });
    setAppActivityData(appActivityDataCopy)
    setDisEvedenceKm(total.distance)
    setDurEvidenceSec(total.duration)
  }

  const clearSelection = () => {
    if(appActivityData) {
      const data = JSON.parse(JSON.stringify(appActivityData))
      setAppActivityData(data.map(e => ({...e, isSelected: false})))
    }
    
  }

  // const handleRemoveQueryParam = () => {
  //   // Get the current location
  //   const { pathname, search } = location;

  //   // Remove the 'app' query parameter
  //   const newSearch = search.replace(/(\?|&)app=strava/, '');

  //   // Navigate to the new location to update the URL
  //   navigate(`${pathname}${newSearch}`);
  // };
  const handleLogout = async() => {
    const isLogout = await disconnectStrava(userId)
    console.log("isLogout", isLogout);
    const { pathname, search } = location;

    // Remove the 'app' query parameter
    const newSearch = search.replace(/(\?|&)app=strava/, '');

    // Navigate to the new location to update the URL
    navigate(`${pathname}${newSearch}`);
    setAppActivityData([])
    
  }

  return (
    <div className="modal ">
      <div className="modal-content">
        <div className="cross-button close-button" onClick={() => {
          setDisEvedenceKm(null);
          setDurEvidenceSec(null);
          clearSelection()
          onClose()
        }}>
          <span className="horizontal-line"></span>
          <span className="vertical-line"></span>
        </div>
        {/* <h4>{waypoint?.title}</h4> */}

        <div className="tab-head">
          {configureAndGetTabs().map((tab) => (
            <div
              onClick={() => handleOptionSelect(tab.id)}
              className={`tab-btn ${tab.id === activeOption ? "active-tab" : "inactive-tab"
                }`}
            >
              {tab.name}
            </div>
          ))}
        </div>

        <div style={{ height: 412, overflow: "scroll" }}>
          {activeOption === 1 ? (
            <div>
              
              <div style={{ textAlign: 'center' }}>
                <span style={{ fontWeight: "bold" }}>CONNECT FITNESS APP</span>
                 <div
              style={{
             display: "flex",
            flexWrap: "wrap",
             justifyContent: "center", // Center the content horizontally
             margin: "10px 0",
    }}
  >

                  

                </div>
                <div className="app-activity-container">
                  
                  {
                    appActivityData?.length ?
                      <>
                      <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                        margin: 20,
                        padding: 10,
                        borderRadius: 10,
                        alignItems: "center"
                        // border: "solid 5px #cb7c46"
                      }}
                      onClick={() => handleLogout()}
                    >
                      <img style={{ height: 50, width: 50 }} src={disconnectLogo} />
                      <label>Disconnect Strava</label>
                    </div>
                        <h4>Select Evidence:</h4>
                        {appActivityData.map(activity =>
                          <div key={activity.id} className="app-activity" style={{border: activity.isSelected ? "2px solid #c17846" : ""}} onClick={() => handleActivitySelect(activity)}>
                            <span style={{ fontWeight: "bold", color: "#000000" }}>{activity.name}</span>
                            <br />
                            <span>Distance: {activity.distance} km</span>
                            <br />
                            <span>Duration: {activity.duration_hms}</span>
                          </div>
                        )}
                      </>
                      :

                      <>
                      {appsToConnect.map((item) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                        margin: 8,
                        padding: 5,
                        borderRadius: 10,
                        border: app === item.name ? "solid 5px #cb7c46" : "none"
                      }}
                      onClick={() => app === item.name ? null : handleClick(item.redirectUrl)}
                    >
                      <img style={{ height: 50, width: 50 }} src={item.logo} />
                      <label>{item.name}</label>
                    </div>
                  ))}</>
                  }
                </div>

              </div>
              <span style={{ fontWeight: "bold", paddingBottom: 5, marginBottom: 12 }}>MANUAL ENTRY</span>

              {evidenceFormConfig.map((eve) => (
                <div style={{ marginBottom: 10, marginTop: 5 }}>{eve.component}</div>
              ))}
              {console.log("disEvedenceKm", disEvedenceKm)}
              <button
                disabled={!disEvedenceKm}
                className="submit-btn"
                onClick={onSubmitEvidence}
              >
                Submit
              </button>
            </div>
          ) : (
            <div>
              <EvidenceHistory
                onClose={onClose}
                getUser={getUser}
                status={status}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;

function DurationConverter({ handleDurationChange, dur }) {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [totalSeconds, setTotalSeconds] = useState(0);

  const handleInputChange = () => {
    console.log({ hours, minutes, seconds });

    const secs = (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
    setTotalSeconds(secs);
    handleDurationChange(secs);
  };

  useEffect(() => {
    const h = Math.floor(dur / 3600);
    const m = Math.floor((dur % 3600) / 60);
    const remainingSeconds = dur % 60;
    setHours(h)
    setMinutes(m)
    setSeconds(remainingSeconds)
  }, [dur])


  const config = [
    {
      key: "hh",
      placeholder: "HH",
      value: hours,
      onchangeFunc: (hh) => setHours(hh),
    },
    {
      key: "mm",
      placeholder: "MM",
      value: minutes,
      onchangeFunc: (mm) => setMinutes(mm),
    },
    {
      key: "ss",
      placeholder: "SS",
      value: seconds,
      onchangeFunc: (ss) => setSeconds(ss),
    },
  ];

  useEffect(() => {
    handleInputChange();
  }, [hours, minutes, seconds]);

  return (
    <div>
      <label>Duration (HH:MM:SS)</label>
      <div style={{ display: "flex" }}>
        {config.map((c, i) => (
          <div>
            <input
              className="dur-inp"
              type="number"
              placeholder={c.placeholder}
              value={c.value}
              onChange={(e) => c.onchangeFunc(parseInt(e.target.value))}
              onBlur={handleInputChange}
            />
            {i !== config.length - 1 && ":"}
          </div>
        ))}
      </div>

      {/* <p>Total seconds: {totalSeconds}</p> */}
    </div>
  );
}

function DistanceConverter({ handleDistanceChange, dis }) {
  const [km, setKm] = useState(0);
  const [miles, setMiles] = useState(0);
  const [steps, setSteps] = useState(0);
  const [activeInput, setActiveInput] = useState("km");


  useEffect(() => {
    // handleKmChange({ target: { value: dis } })
    const newKm = parseFloat(dis);
    const newMiles = parseFloat((newKm * 0.621371).toFixed(2));
    const newSteps = newKm * 1300;
    setKm(newKm);
    setMiles(newMiles); // 1 km = 0.621371 miles
    if(activeInput !== "steps") {
      setSteps(newSteps); 

    }

  }, [dis])


  const handleKmChange = (event) => {    
    const newKm = parseFloat(event.target.value);
    const newMiles = parseFloat((newKm * 0.621371).toFixed(2));
    const newSteps = newKm * 1300;
    setKm(newKm);
    setMiles(newMiles); // 1 km = 0.621371 miles
    setSteps(newSteps); // 1 km = 1300 steps
    handleDistanceChange(newKm);
  };

  const handleMilesChange = (event) => {
    const newMiles = parseFloat(event.target.value);
    const newKm = parseFloat((newMiles / 0.621371).toFixed(2));
    const newSteps = parseFloat(((newMiles * 1300) / 0.621371).toFixed(0));
    setMiles(newMiles);
    setKm(newKm); // 1 mile = 0.621371 km
    setSteps(newSteps); // Convert miles to km and then to steps
    handleDistanceChange(newKm);
  };

  const handleStepsChange = (event) => {
    const newSteps = parseFloat(event.target.value);
    const newKm = parseFloat((newSteps / 1300).toFixed(2));
    const newMiles = parseFloat(((newSteps * 0.621371) / 1300).toFixed(2));
    setSteps(newSteps);
    setKm(newKm); // 1300 steps = 1 km
    setMiles(newMiles); // Convert steps to km and then to miles
    handleDistanceChange(newKm);
  };

  const config = [
    {
      key: "km",
      label: "Distance in KM: (e.g. 3.40)",
      placeholder: "enter your km",
      value: km,
      onChangeFunc: handleKmChange,
    },
    {
      key: "miles",
      label: "Distance in Miles: (e.g 3.40)",
      placeholder: "enter Your miles",
      value: miles,
      onChangeFunc: handleMilesChange,
    },
    {
      key: "steps",
      label: "Steps: (1300 Steps = 1KM or 0.62 Miles)",
      placeholder: "enter steps",
      value: steps,
      onChangeFunc: handleStepsChange,
    },
  ];

  return (
    <div>
      {config.map((c, i) => (
        <div style={{}}>
          <label style={{ marginBottom: 10 }}>{c.label}</label>

          <div>
            <input
              style={{ marginTop: 10, padding: 10, borderRadius: 15, fontSize: 18, width: "90%" }}
              type="number"
              placeholder={c.placeholder}
              value={c.value}
              onChange={activeInput === c.key ? c.onChangeFunc : () => console.log("")}
              onFocus={() => setActiveInput(c.key)}
              onBlur={() => setActiveInput("")}
            />
          </div>
          {i !== config.length - 1 && <p>or</p>}
        </div>
      ))}
    </div>
  );
}

function EvidenceHistory({ onClose, getUser, status }) {
  const params = useParams();
  const { productId, userId } = params;

  const [evidences, setEvidences] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function formatDate(timestamp) {
    const date = new Date(timestamp);

    const options: any = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    const formattedTime = `${hours % 12 || 12}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return `${formattedDate}, ${formattedTime}`;
  }

  useEffect(() => {
    getEvidences();
  }, []);

  const getEvidences = async () => {
    const data = await getUserEvidences(productId, userId);
    setIsLoading(false);
    setEvidences(data);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Do you want to delete?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Don't delete`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          let response = await deleteUserEvidence(id);
          if (response?.is_success) {
            getUser();

            Swal.fire("Evidence deleted successfully", "success");
          } else {
            Swal.fire("Something went wrong", "Try again later", "error");
          }
          onClose();
        } catch (error) {
          console.log("err", error);
          Swal.fire("Something went wrong", "Try again later", "error");
          onClose();
        }
      }
    });
  };

  return (
    <>
      {!isLoading
        ? evidences.map((evidence) => (
          <div className="evidence" key={evidence._id}>
            <div>
              <span>{formatDate(evidence.created_on)}</span>
              <br />
              <span>Distance: {evidence.distanceKm} km</span>

              {evidence.durationSec ? (
                <>
                  <br />
                  <span>Duration: {evidence.duration_hms}</span>
                </>
              ) : null}
            </div>

            {!status ? (
              <button
                onClick={() => handleDelete(evidence._id)}
                style={{
                  height: 25,
                  borderRadius: 100,
                  alignSelf: "center",
                  cursor: "pointer",
                }}
              >
                delete
              </button>
            ) : null}
          </div>
        ))
        : "loading..."}
    </>
  );
}

function ImageUploader () {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
      alert('Please select an image file.');
    }
  };

  return (
    <div>
      <p>Add a photo:</p>
      <input type="file" accept="image/*" onChange={handleFileChange} />
      {selectedImage && (
        <div>
          <h3>Selected Image:</h3>
          <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%' }} />
        </div>
      )}
    </div>
  );
};
