import React, {useEffect, useState} from 'react'
import "./style.css"
import vclogo from "../../assets/logo.png"
import { getBrands } from '../../globalService/api.service'
import { useNavigate } from 'react-router-dom'

export default function Landing() {
    const [brands, setbrands] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
      
        getAllBrands()
      return () => {
      }
    }, [])

    const getAllBrands = async() => {
        const data = await getBrands()
        setbrands(data)
    }
    
  return (
    <div className='container'>
        <div className='landing'>
        {console.log("brands", brands)}
        <div style={{fontSize: "1.5rem", color: "#957259", fontWeight: 'bold', fontFamily: "cursive"}}>
            Welcome to Virtual Challenge
        </div>
        <p>Our Brands</p>
        <div className='brands'>
        {
            brands.map(brand => <div key={brand.key} className='vc' onClick={() => navigate(`/${brand.key}`)}>
                {brand.name}
                </div>)
        }
        </div>
        
    </div>
    </div>
    
  )
}
