import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import './challenges-style.css';
import { assets_base_url, getChallengesForMap } from '../../globalService/api.service';
import _ from "lodash";
import { Challenge } from '../../globalService/api.model';
import Icon1 from '../../assets/Distanceiconblack.png';
import Icon2 from '../../assets/trainericon.png';
import Icon3 from '../../assets/calendaricon.png';
import startIcon from '../../assets/start-icon.png';

export default function ChallengeComponent() {
  const navigate = useNavigate();
  const [myChallenges, setMyChallenges] = useState([]);
  const [shopChallenges, setShopChallenges] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [challengesCopy, setChallengesCopy] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [racenumber, setRecenumber] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [filterPercentage, setFilterPercentage] = useState(false);
  const [filterTimeRemaining, setFilterTimeRemaining] = useState(false);
  const [filterZeroCompletion, setFilterZeroCompletion] = useState(false);
  const [filterDistance, setFilterDistance] = useState(false);
  const [totalDistance, setTotalDistance] = useState(0);
  const [completedChallenges, setCompletedChallenges] = useState(0);
  const [activeChallenges, setActiveChallenges] = useState(0);
  const [totalDistanceKm, setTotalDistanceKm] = useState(0);
  const [isKilometers, setIsKilometers] = useState(true);

  const params = useParams();
  const { userId } = params;
  const tabs = [
    { id: 1, name: "Challenges" },
    { id: 2, name: "Completed" }
  ];

  const inputCallback = _.debounce(function (text) {
    const searchResult = challengesCopy.filter(challenge => challenge.name.match(new RegExp(text, "i")));
    setMyChallenges(searchResult);
    setSearchText(text);
  }, 500);

  useEffect(() => {
    localStorage.setItem('brandId', params.brandId);
    setIsLoading(true);
    getChallenges();
    return () => { };
  }, []);

  useEffect(() => {
    const copyChallenge = JSON.parse(JSON.stringify(challenges));
    let filteredChallenges = [...copyChallenge];

    if (filterPercentage) {
      filteredChallenges = filteredChallenges.filter((e) => e.percentage < 100);
    }

    if (filterTimeRemaining) {
      filteredChallenges = filteredChallenges.filter((e) => e.daysLeftToComplete > 0);
    }

    if (filterZeroCompletion) {
      filteredChallenges = filteredChallenges.filter((e) => e.percentage === 0);
    }

    if (filterDistance) {
      filteredChallenges = filteredChallenges.filter((e) => e.distanceKm > 0);
    }

    if (filterPercentage) {
      filteredChallenges.sort((a, b) => b.percentage - a.percentage);
    } else if (filterTimeRemaining) {
      filteredChallenges.sort((a, b) => a.daysLeftToComplete - b.daysLeftToComplete);
    }

    if (filterDistance) {
      filteredChallenges.sort((a, b) => b.distanceKm - a.distanceKm);
    }

    setChallengesCopy(filteredChallenges);
    setMyChallenges(filteredChallenges.filter((e) => e.percentage < 100));
    setShopChallenges(filteredChallenges.filter((e) => e.percentage >= 100));

    let totalDist = 0;
    let completed = 0;
    let active = 0;
    filteredChallenges.forEach(challenge => {
      totalDist += challenge.totalDistanceKm;
      if (challenge.percentage >= 100) {
        completed++;
      } else {
        active++;
      }
    });
    setTotalDistance(totalDist);
    setCompletedChallenges(completed);
    setActiveChallenges(active);

    let totalDistKm = 0;
    filteredChallenges.forEach(challenge => {
      totalDistKm += challenge.distanceKm;
    });
    setTotalDistanceKm(totalDistKm);
  }, [filterPercentage, filterTimeRemaining, filterZeroCompletion, filterDistance, challenges]);

  const getChallenges = async () => {
    const data = await getChallengesForMap(userId);
    setIsLoading(false);
    setChallenges(data);
  };

  const onChangeHandler = (event) => {
    event.persist();
    inputCallback(event.target.value);
  };

  const onUserIdType = (event) => {
    const { value } = event.target;
    setRecenumber(value);
  };

  const onUserIdSubmit = () => {
    localStorage.setItem('userId', racenumber);
  };

  const getMapImage = (img) => {
    if (isJSONParsable(img)) {
      return `${assets_base_url}/${JSON.parse(img).image_intro}`;
    } else {
      return img;
    }
  };

  function isJSONParsable(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  const inputEl = useRef(null);

  const clearSearch = () => {
    inputEl.current.value = "";
    setMyChallenges(challengesCopy);
  };

  const resetUser = () => {
    localStorage.removeItem('userId');
  };

  const handleTabChange = (tab) => {
    if (tab === activeTab) return;
    const copyChallenge = JSON.parse(JSON.stringify(challenges));
    if (tab === 1) {
      setChallengesCopy(copyChallenge.filter((e) => e.percentage < 100));
      setMyChallenges(copyChallenge.filter((e) => e.percentage < 100));
    } else {
      setChallengesCopy(copyChallenge.filter((e) => e.percentage >= 100));
      setShopChallenges(copyChallenge.filter((e) => e.percentage >= 100));
    }
    setActiveTab(tab);
  };

  const handleFilterPercentage = () => {
    setFilterPercentage(!filterPercentage);
  };

  const handleFilterTimeRemaining = () => {
    setFilterTimeRemaining(!filterTimeRemaining);
  };

  const handleFilterZeroCompletion = () => {
    setFilterZeroCompletion(!filterZeroCompletion);
  };

  const handleFilterDistance = () => {
    setFilterDistance(!filterDistance);
  };

  const toggleDistanceUnit = () => {
    setIsKilometers(!isKilometers);
  };

  const getChallengeColorClass = (percentage) => {
    if (percentage <= 1) return 'white';
    else if (percentage <= 20) return 'light-bronze';
    else if (percentage <= 40) return 'bronze';
    else if (percentage <= 60) return 'light-silver';
    else if (percentage <= 75) return 'silver';
    else if (percentage <= 90) return 'light-gold';
    else return 'gold';
  };

  return (
    <div style={{ margin: 0, backgroundColor: '#f6f6f8' }}>
      <div>
        <div className='tab-head'>
          {tabs.map(tab => <div className={`tab-btn ${tab.id === activeTab ? 'active-tab' : 'inactive-tab'}`} onClick={() => handleTabChange(tab.id)}>{tab.name}</div>)}
        </div>

        <div className='user-stats user-details-row'>
          <div className="user-details-box">
            <p style={{ fontSize: '1.2em', fontWeight: 'bold', margin: 0 }}>
              {isKilometers ? totalDistance.toFixed(0) : (totalDistance * 0.621371).toFixed(1)} {isKilometers ? 'KM' : 'Mi'}
            </p>
            <p style={{ fontSize: '0.8em', margin: 0 }}>Target</p>
          </div>
          <div className="user-details-box">
            <p style={{ fontSize: '1.2em', fontWeight: 'bold', margin: 0 }}>{completedChallenges}</p>
            <p style={{ fontSize: '0.8em', margin: 0 }}>Completed</p>
          </div>
          <div className="user-details-box">
            <p style={{ fontSize: '1.2em', fontWeight: 'bold', margin: 0 }}>{activeChallenges}</p>
            <p style={{ fontSize: '0.8em', margin: 0 }}>Active</p>
          </div>
          <div className="user-details-box">
            <p style={{ fontSize: '1.2em', fontWeight: 'bold', margin: 0, color: '#c17846' }}>
              {isKilometers ? totalDistanceKm.toFixed(0) : (totalDistanceKm * 0.621371).toFixed(1)} {isKilometers ? 'KM' : 'Mi'}
            </p>
            <p style={{ fontSize: '0.8em', margin: 0 }}>So Far</p>
          </div>
        </div>
      </div>

      <div className='filter-buttons'>
        <button className={filterPercentage ? 'active' : ''} onClick={handleFilterPercentage}><img src={Icon2} alt="Trainer icon" /></button>
        <button className={filterTimeRemaining ? 'active' : ''} onClick={handleFilterTimeRemaining}><img src={Icon3} alt="Calendar icon" /></button>
        <button className={filterZeroCompletion ? 'active' : ''} onClick={handleFilterZeroCompletion}><img src={startIcon} alt="Start icon" /></button>
        <button className={filterDistance ? 'active' : ''} onClick={handleFilterDistance}><img src={Icon1} alt="Distance icon" /></button>
        <button onClick={toggleDistanceUnit}>
          {isKilometers ? 'Mi' : 'KM'}
        </button>
      </div>

      <div className='challenge-container' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
        {!isLoading ? (
          <>
            {challenges.length ? (activeTab === 1 ? myChallenges?.map(challenge => (
              <div key={challenge.id} onClick={() => navigate(`/map/${params.brandId}/${challenge.productId}/${userId}`)} className={`challenge-card my-challenge-card ${getChallengeColorClass(challenge.percentage)}`} style={{ display: 'inline-block', margin: '1px 10px' }}>

                <div style={{ textAlign: 'center' }}>
                  <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{challenge.name}</p>
                  <p>
                    {challenge.totalDistanceKm} KM/{(challenge?.totalDistanceKm * 0.621371).toFixed(1)}Mi
                  </p>
                </div>
                <div style={{ position: "relative" }}>
                  <div className='challenge-user-info'>
                    <p></p>
                    <div className="challenge-details-row">
                      <div className="challenge-details-box">
                        <img src={Icon1} alt="icon" />
                        <p>{(isKilometers ? challenge.distanceKm : challenge.distanceKm * 0.621371).toFixed(1)} {isKilometers ? 'KM' : 'Mi'}</p>
                      </div>
                      <div className="challenge-details-box">
                        <img src={Icon2} alt="icon" />
                        <p>{challenge.percentage.toFixed(1)}%</p>
                      </div>
                      <div className="challenge-details-box">
                        <img src={Icon3} alt="icon" />
                        {challenge?.daysLeftToComplete || challenge?.daysLeftToComplete === 0 ? (
                          <p style={{ color: challenge?.daysLeftToComplete <= 10 ? "red" : "black" }}>
                            {challenge?.daysLeftToComplete === 0
                              ? "Ended"
                              : challenge.daysLeftToComplete + " days"}
                          </p>
                        ) : (
                          <img src={startIcon} alt="icon" />
                        )}
                      </div>
                    </div>
                  </div>
                  <img className='challenge-img' src={getMapImage(challenge.images)} />
                </div>
              </div>
            )) :
              shopChallenges?.map(challenge => (
                <div key={challenge.id} onClick={() => navigate(`/map/${params.brandId}/${challenge.productId}/${userId}`)} className={`challenge-card my-challenge-card ${getChallengeColorClass(challenge.percentage)}`} style={{ display: 'inline-block', margin: '0 10px' }}>
                  <p>{challenge.name}</p>
                  <div style={{ position: "relative" }}>
                    <img className='challenge-img' src={getMapImage(challenge.images)} />
                  </div>
                </div>
              ))) : (<p>No Challenges Found</p>)}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div className="loader"></div>
            <p>Please wait</p>
          </div>
        )}
      </div>
    </div>
  );
}
