import React from "react";
import "./style.css";
import distanceicon from "../../assets/Distanceicon.png";
import timeicon from "../../assets/timeicon.png";
import uploadicon from "../../assets/upload.png";
import Toggle from "./Toggle";

export default function ({activity, handleOpenEvModal, handleToggle}) {
    console.log("activity", activity);

    function secondsToHMS(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
      
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
      
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    // Define a function to determine the color based on the percentage
    function getColor(percentage) {
        if (percentage <= 1) return '#f2f2f2';
        else if (percentage <= 20) return '#e1aa85';
        else if (percentage <= 40) return '#c17746';
        else if (percentage <= 60) return '#dad9d9';
        else if (percentage <= 75) return '#b8b7b7';
        else if (percentage <= 90) return '#eac912';
        else return '#fbcd15'; // Assuming > 90% is gold
    }

    // Get the color based on activity.percentage
    const boxColor = getColor(activity.percentage);

    // Define a style object with the background color
    const boxStyle = {
        backgroundColor: boxColor
    };

    return (
        <div className="activity">
            <div>
            <Toggle handleToggle={() => handleToggle()}/>
            </div>
            <div className="box" style={boxStyle}>
                {activity.percentage >= 100 ? (
                    <div className="txt" style={{fontSize: 20}}>Well Done! Challenge Completed</div>
                ) : (
                    <>
                        <div className="txt" style={{fontSize: 15}}>CHALLENGE&nbsp; &nbsp; </div>
                        <div className="txt" style={{fontSize: 40}}>{activity.percentage}%</div>
                        <div className="txt" style={{fontSize: 15}}>&nbsp; COMPLETED</div>
                    </>
                )}
            </div>
            <div className="block">
                <div className="node">
                    <div className="node-1">
                        <img className="activity-icon" src={distanceicon} />
                        <div className="txt">{(activity?.distanceKm * 0.621371).toFixed(1)}Mi / {activity.distanceKm.toFixed(1)}KM</div>
                    </div>
                </div>

                <div className="node " >
                    <div className="node-1" onClick={handleOpenEvModal}>
                        <img className="activity-icon" src={uploadicon} />
                        <div className="txt">SUBMIT</div>
                    </div>
                </div>

                <div className="node">
                    <div className="node-1">
                        <img className="activity-icon" src={timeicon} />
                        <div className="txt">{secondsToHMS(activity.durationSec)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
