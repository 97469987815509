import axios from "axios";
import { User, Challenge, WayPoint, Brands } from "./api.model";

export const base_url = "https://virtualchallenge-platform-3edceab55a5f.herokuapp.com"//"https://virtual-challenge-server.onrender.com"//'https://virtual-challenge-maps.herokuapp.com';

export const assets_base_url = 'https://medalmad.com'

// const endPoints = {
//     usersByProduct: "usersByProduct"
// }

const getUrl = (endPoint: string):string => {

    const brandId = localStorage.getItem('brandId')
    return `${base_url}/${brandId}/${endPoint}`
}

export const getBrands = async(): Promise<Brands[] | undefined> => {
    try {
        const response = await axios.get(`${base_url}/brands`);
        // console.log(response.data);
        
        if(response) return response.data;
    } catch (error: any) {
        console.log(error);
        
        return error.message
    }
}

export const getChallengesForMap = async(userId: string = null): Promise<Challenge[] | undefined> => {
    try {
        const response = await axios.get(getUrl(`${userId}/products`));
        // console.log(response.data);
        
        if(response) return response.data;
    } catch (error: any) {
        console.log(error);
        
        return error.message
    }
}

export const getUsersByProductId = async(productId: string, userId: string): Promise<User[] | undefined> => {
    try {
        const response = await axios.get(`${getUrl(`users`)}/${productId}/${userId}`);
        if(response) return response.data;
    } catch (error: any) {
        return error.message
    }
}

export const getChallengeById = async(productId: string): Promise<Challenge[] | undefined> => {
    try {
        const response = await axios.get(`${getUrl('challengeById')}/${productId}`);
        if(response) return response.data;
    } catch (error: any) {
        return error.message
    }
}

export const getWaypointsById = async(productId: string, userId: string): Promise<WayPoint[] | undefined> => {
    try {
        const response = await axios.get(`${getUrl('waypoints')}/${productId}/${userId}`);
        if(response) return response.data;
    } catch (error: any) {
        return error.message
    }
}

export const unlockWaypoint = async(waypointId: string, userId: string, thingsToUpdate: {answer?: number}): Promise<WayPoint[] | undefined> => {
    try {
        const response = await axios.put(`${getUrl(`waypoints/unlock/${waypointId}/${userId}`)}`, thingsToUpdate);
        if(response) return response.data;
    } catch (error: any) {
        return error.message
    }
}

export const submitEvidence = async(productId: string, userId: string, thingsToUpdate: { distanceKm: number, durationSec: number }) => {
    try {
        const response = await axios.post(`${getUrl(`evidence/${productId}/${userId}`)}`, thingsToUpdate);
        if(response) return response.data;
    } catch (error: any) {
        return error.message
    }
}

export const getUserActivity = async(productId: string, userId: string) => {
    try {
        const response = await axios.get(`${getUrl(`useractivity/${productId}/${userId}`)}`);
        if(response) return response.data;
    } catch (error: any) {
        return error.message
    }
}

export const getUserEvidences = async(productId: string, userId: string) => {
    try {
        const response = await axios.get(`${getUrl(`evidence/${productId}/${userId}`)}`);
        if(response) return response.data;
    } catch (error: any) {
        return error.message
    }
}

export const deleteUserEvidence = async(evidenceId: string) => {
    try {
        const response = await axios.delete(`${getUrl(`evidence/${evidenceId}`)}`);
        if(response) return response.data;
    } catch (error: any) {
        return error.message
    }
}

export const getStravaActivity = async(userId: string) => {
    try {        
        const response = await axios.get(`${getUrl(`strava/activities/${userId}`)}`);
        if(response) return response.data;
    } catch (error: any) {
        console.log("eeee", error);
        
        return error
    }
}

export const disconnectStrava = async(userId: string) => {
    try {
        const response = await axios.post(`${getUrl(`strava/logout/${userId}`)}`);
        if(response) return response.data;
    } catch (error) {
        console.log(error);
        return error
        
    }
}

export const checkChallengeValidity = async(productId: string, userId: string) => {
    try {
        const response = await axios.post(`${getUrl(`challenge-validity/${productId}/${userId}`)}`);
        if(response) return response.data;
    } catch (error) {
        console.log(error);
        return error
        
    }
}

export const downloadWaypointImage = async(waypointId: string, userId: string) => {
    try {
        const response = await axios.post(`${getUrl(`image-download/${waypointId}/${userId}`)}`);
        if(response) return response.data;
    } catch (error) {
        console.log(error);
        return error
        
    }
}