import React, { useState } from 'react';
import './Toggle.css'; // You can create this CSS file for styling
import marker from '../../assets/marker.png'

const Toggle = ({handleToggle}) => {    
  
  return (
    <label className="label">
    <div className="toggle">
      <input onChange={(event) => {
        // event.preventDefault()
        // event.stopPropagation()        
        handleToggle()
    }} className="toggle-state" type="checkbox" name="check" value="check" />
      <div className="indicator">
        <img src={marker}/>
        show
      </div>
    </div>
  </label>
  
  );
};

export default Toggle;
