import './App.css';
import {BrowserRouter, Route, Routes,} from 'react-router-dom'
import { routes } from './globalConfig/router';
//Entry Point

function App() {
  return (
    <div >
      <BrowserRouter>
    <Routes>
      {
        routes.map(route => <Route key={route.path} path={route.path} element={route.component} />)
      }
    </Routes>
  </BrowserRouter>
      
     
    </div>
  );
}

export default App;
